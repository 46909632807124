import { areaConstants } from '../_constants';

let areas = JSON.parse(localStorage.getItem('areas'));
const initialState = areas ? { areas } : {};

export function areas(state = initialState, action) {
    switch (action.type) {
        case areaConstants.FETCH_QUOTAS_REQUEST:
            return {
                loading: true,
                areas: action.areas
            };
        case areaConstants.FETCH_QUOTAS_SUCCESS:
            return {
                loading: false,
                areas: action.areas
            };
        case areaConstants.FETCH_QUOTAS_FAILURE:
            return {};
        default:
            return state
    }
}