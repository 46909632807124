import React from 'react';
import PropTypes from 'prop-types';


class AreaItem extends React.Component {

    render() {
        const {area, index} = this.props;
        return (<div className="card mt-5" key={ index }>
            <h3 className="card-header bg-info text-white">
              { area.area_name }
            </h3>
                <div className="card-body">
                    <h4 className="card-subtitle mb-2 text-muted">{ area.manager.name }</h4>

                    <h5>Verteilung der Angelscheine gem. Bescheid</h5>
                    <dl className="row">
                        <dt className="col-sm-3">Kartentyp</dt>
                        <dt className="col-sm-3 bg-light">Gesamt</dt>
                        <dt className="col-sm-3">Verbraucht</dt>
                        <dt className="col-sm-3 bg-light">Rest</dt>
                    </dl>
                    { area.ticket_consumption.data.map((ticketType, index) => {
                            if (ticketType.quota_allocation && ticketType.quota_allocation > 1 && ticketType.quota_max > 0) {
                            return (
                                <dl className="row" key={ `t_${index}`}>
                                    <dt key={ ticketType.index } className="col-sm-3">{ ticketType.name } </dt>
                                    <dd key={ ticketType.index } className="col-sm-3 bg-light">{ ticketType.quota_max }</dd>
                                    <dd key={ ticketType.index } className="col-sm-3">{ ticketType.quota_max -
                                    ticketType.sold_tickets < 0 ? ticketType.quota_max : ticketType.sold_tickets }</dd>
                                    <dd key={ ticketType.index } className="col-sm-3 bg-light">
                                        { ticketType.quota_max - ticketType.sold_tickets < 0 ? 0 : ticketType.quota_max - ticketType.sold_tickets }
                                    </dd>
                                </dl>
                            )} else if (ticketType.quota_allocation && ticketType.quota_max > 0) {
                                return (
                                <dl className="row" key={ `t_${index}`}>
                                    <dt key={ ticketType.index } className="col-sm-3">{ ticketType.name } </dt>
                                    <dd key={ ticketType.index } className="col-sm-3 bg-light">{ ticketType.quota_max }</dd>
                                    <dd key={ ticketType.index } className="col-sm-3">{ ticketType.sold_tickets }</dd>
                                    <dd key={ ticketType.index } className="col-sm-3 bg-light">{ ticketType.quota_max -
                                    ticketType.sold_tickets < 0 ? 0 : ticketType.quota_max -
                                        ticketType.sold_tickets } </dd>
                                </dl>)
                            }
                        }
                    ) }
                    <a href={ area.notice_file_url } target="_blank" className="card-link text-info"><i
                        className="material-icons">file_copy</i>Bescheid: { area.notice_id }</a>
                </div>
            </div>
        );
    }
}

AreaItem.propTypes = {
    area: PropTypes.object.isRequired
}

export { AreaItem };
