import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions, areaActions } from '../_actions';
import { AreaItem } from '../AreaItem';

class HomePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      managers: [],
      selectedManager: null,
      searchQuery: '',
      years: [],
      selectedYear: 0
    };
  }

  componentDidMount() {
    const currentYear = moment().year();
    const years = [];
    for (let y = 2019; y <= currentYear + 5; y++) {
      years.push(y);
    }
    this.setState({ years });
    this.loadAreas(currentYear);
  }

  componentWillReceiveProps(nextProps) {
    const {areas} = nextProps;
    if (areas && areas.areas) {
      const areaManagers = areas.areas.data.map(area => area.manager);
      const countManagers = areaManagers.map(areaManager => {
        return {
          id: areaManager.id,
          name: areaManager.name
        };
      });
      let managersWithCount = countManagers.reduce((areaManagers, manager) => {
        let bool = false;
        if (!areaManagers) {
          areaManagers = [];
        }
        areaManagers.forEach((area) => {
          if (area.name === manager.name) {
            area.count++;
            bool = true;
          }
        });
        if (!bool) {
          manager.count = 1;
          areaManagers.push(manager);
        }
        return areaManagers;
      }, []);
      this.setState({managers: managersWithCount, filteredAreas: areas.areas.data});
    }
  }

  loadAreas(year) {
    this.setState({ selectedYear: year });
    this.props.dispatch(areaActions.getAll(year));
  }

  handleSearch(e) {
    const { value } = e.target;
    this.setState({ searchQuery: value });
  };

  selectManager(e) {
    const { value } = e.target;
    this.setState({ selectedManager: Number(value) });
  }

  selectYear(e) {
    const {value} = e.target;
    this.loadAreas(Number(value));
  }

  getFilteredAreas() {
    const { areas } = this.props;
    const { selectedManager, searchQuery } = this.state;

    if (!areas || !areas.areas) {
      return [];
    }

    let filteredAreas = areas.areas.data;

    if (selectedManager) {
      filteredAreas = filteredAreas.filter(area => {
        return area.manager.id === selectedManager;
      });
    }

    if (searchQuery) {
      filteredAreas = filteredAreas.filter(area => {
        const query = searchQuery.toLowerCase();
        return area.area_name.toLowerCase().includes(query) || area.manager.name.toLowerCase().includes(query);
      });
    }

    return filteredAreas;
  }

  render() {
    const { areas } = this.props;
    const { managers, years, selectedYear } = this.state;
    const filteredAreas = this.getFilteredAreas();

    if (!areas.loading && !areas.areas) {
      return (
          <div className="text-danger"> Sie besitzen nicht die benötigte Berechtigung diese Seite zu sehen.</div>
      )
    }

    return (<div>
        <div className="jumbotron">
          <h1 className="text-info"> Guten Tag! </h1>
          <p>Übersicht über alle Gewässerkontingente in Bayern</p>
        </div>

        <div>
          <h4>Wählen Sie Gewässerbetreiber & Jahr</h4>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text">Gewässerbetreiber</label>
            </div>
            <select onChange={ e => this.selectManager(e) } className="form-control form-control-lg">
              <option value="">Alle</option>
              { managers.map(manager => (
                  <option value={ manager.id } key={`m_${manager.id}` }>{ manager.name } ({ manager.count })</option>
                )
              ) }
            </select>
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text">Jahr</label>
            </div>
            <select onChange={ e => this.selectYear(e) } className="form-control form-control-lg" value={ selectedYear }>
              { years.map(year => <option value={ year } key={ `y_${year}` }>{ year }</option>) }
            </select>
          </div>

          <h5>Suchen Sie nach Gewässernamen oder Betreiber</h5>
          <div className="input-group mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text" id="search">Suche</span>
            </div>
            <input type="text" className="form-control" onChange={ e => this.handleSearch(e) } id="search"
                   aria-describedby="search"/>
          </div>
        </div>

        { areas.loading && <p><em> Lade Gewässer in Bayern... </em></p> }

        { areas && areas.areas &&
          <h5>Gewässer: { filteredAreas && filteredAreas.length }</h5>
        }
        { filteredAreas && filteredAreas.map((area, index) =>
          <AreaItem area={ area } index={ index } key={ index }/>
        )}

        <hr />
        <p>
          <Link className="text-info" to="/login"> Ausloggen </Link>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {areas, authentication} = state;
  const {user} = authentication;
  return {
    user,
    areas
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };