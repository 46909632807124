import { areaConstants } from '../_constants';
import { areaService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';

export const areaActions = {
    getAll
};

function getAll(year) {
    return dispatch => {
        dispatch(request());

        areaService.getAll(year)
            .then(
                areas => dispatch(success(areas)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: areaConstants.FETCH_QUOTAS_REQUEST } }

    function success(areas) { return { type: areaConstants.FETCH_QUOTAS_SUCCESS, areas } }

    function failure(error) { return { type: areaConstants.FETCH_QUOTAS_FAILURE, error } }
}